@import url(https://fonts.googleapis.com/css?family=Merienda+One|Open+Sans:400,400italic,600);
/*
  Filename: styles.css
  Date: 21 Jul 16
  Programmer: Jaymes Young
*/
/*
  Filename: _index.scss
  Date: 20 Jul 16
  Programmer: Jaymes Young
*/
/*
  Filename: _reset.scss
  Date: 20 Jul 16
  Programmer: Jaymes Young
*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* GENERIC STYLESHEET */
/*

BOX-SIZING CHANGE (box-sizing property)

The following CSS changes the default box-sizing of box elements from
content-box to border-box.

border-box automatically includes border *and* padding sizes into your
box property widths. No more subtracting padding and border sizes from
your box widths! Now you can use responsive grids without fear of 
making your columns too wide and causing that annoying left-right
scroll bar at the bottom of the page.

Remember: this does not take margins into account since margins are 
not technically part of the box. For those, you will still have to 
do some subtraction. 

*/
* {
  box-sizing: border-box; }

/*

TEXT RENDERING (text-rendering property)

Some fonts are built to handle certain combinations of lettering through
the use of something called ligatures. For instance, the letters "fi" in
a serif font can look odd if the top curve of the f runs into the dot of
the i next to it. A ligature styles them both beautifully. Your browser
generally does this for you but not on font sizes smaller than 20pt. This
text-rendering code changes that. This one is entirely optional and you
can remove this first line if you're not comfortable with it.


FONT SMOOTHING (font-smoothing property)

At this point in time, the font smoothing your browser does by default
is imperfect and makes the font too thick. Using font-smoothing in CSS is
subtle on a standard display but impressive in a high-definition display.
There are two declarations to cover usage in Webkit and Mozilla browsers.

*/
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*

USE IMAGES, EMBEDS AND OBJECTS FREELY WITHOUT WORRYING ABOUT SIZES AND
BREAKING YOUR LAYOUTS (max-width property)

The following ensures that images and embedded content you add can't be 
wider than the box element they are currently in. This is important for 
responsive design, as this allows you to use images without having to 
specify width and height attributes in the HTML.

*/
img, embed, object {
  max-width: 100%; }

/*

DEFAULT BEHAVIOR OF SELECTED HYPERLINKS (outline property)

No more ugly dotted outlines on your active links. This is not very 
noticeable until it's pointed out, but it makes for a cleaner user
experience.

*/
a {
  outline: none; }

/*
  Filename: _config.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
/*$font-url--google           : 'https://fonts.googleapis.com/css?family=Righteous';
$font-family--primary       : Verdana, sans-serif;
$font-family--secondary     : 'Righteous', Impact, Charcoal, cursive, sans-serif;*/
/*
  Filename: _extends.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
.main-header__wrap {
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 544px) {
    .main-header__wrap {
      max-width: 676px; } }
  @media (min-width: 720px) {
    .main-header__wrap {
      max-width: 820px; } }
  @media (min-width: 940px) {
    .main-header__wrap {
      max-width: 1140px; } }

.content__heading, .content__text, .inner, .hero__heading, .main-footer__wrap {
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 544px) {
    .content__heading, .content__text, .inner, .hero__heading, .main-footer__wrap {
      max-width: 576px; } }
  @media (min-width: 720px) {
    .content__heading, .content__text, .inner, .hero__heading, .main-footer__wrap {
      max-width: 720px; } }
  @media (min-width: 940px) {
    .content__heading, .content__text, .inner, .hero__heading, .main-footer__wrap {
      max-width: 940px; } }

.projects__wrap {
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%; }
  @media (min-width: 544px) {
    .projects__wrap {
      max-width: 576px; } }
  @media (min-width: 720px) {
    .projects__wrap {
      max-width: 720px; } }
  @media (min-width: 940px) {
    .projects__wrap {
      width: 90%;
      max-width: 940px; } }

.main-header__brand, .main-header__btn {
  height: 40px;
  line-height: 40px;
  margin-bottom: 0;
  font-size: 1.5em; }

.main-menu__item, .main-menu__item--current,
.main-menu li {
  margin-bottom: 5px;
  opacity: 0;
  padding: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px); }

.main-menu__item a, .main-menu__item--current a,
.main-menu li a {
  display: block;
  font-family: "Merienda One", "Brush Script MT", cursive;
  font-size: 2em;
  font-weight: bold;
  line-height: 55px;
  text-align: center;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in; }

.contact-form__text, .contact-form__textarea, .contact-form__submit {
  background-color: transparent;
  display: block;
  border: solid #ededed;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  padding: 0 0 3px 5px;
  margin-bottom: 15px;
  width: 100%; }
  @media (min-width: 544px) {
    .contact-form__text, .contact-form__textarea, .contact-form__submit {
      margin-bottom: 20px; } }

/*
  Filename: _base.scss
  Date: 18 Feb 17
  Programmer: Jaymes Young
*/
body {
  background: #000;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1, h2, h3, header p {
  font-family: "Merienda One", "Brush Script MT", cursive;
  color: #ffc52a; }

h1 {
  font-size: 1.5em;
  line-height: 1;
  font-weight: normal;
  margin-bottom: 20px; }
  @media (min-width: 544px) {
    h1 {
      font-size: 2.5em;
      line-height: 1.125em; } }
  @media (min-width: 720px) {
    h1 {
      font-size: 3em;
      line-height: 1.05em; } }

h2 {
  font-size: 1.625em;
  line-height: 1.15385em;
  font-weight: normal; }
  @media (min-width: 544px) {
    h2 {
      font-size: 2em;
      line-height: 1.125em; } }
  @media (min-width: 720px) {
    h2 {
      font-size: 2.5em; } }

h3 {
  font-size: 1.375em;
  line-height: 1.15385em;
  font-weight: normal;
  margin-bottom: 15px; }
  @media (min-width: 544px) {
    h3 {
      font-size: 1.5em;
      line-height: 1.125em; } }
  @media (min-width: 720px) {
    h3 {
      font-size: 1.75em; } }

p {
  font-size: 1.375em;
  line-height: 1.15385em;
  font-weight: normal;
  margin-bottom: 15px;
  margin-bottom: 1.875em; }
  @media (min-width: 544px) {
    p {
      font-size: 1.5em;
      line-height: 1.125em; } }
  @media (min-width: 720px) {
    p {
      font-size: 1.75em; } }

a {
  text-decoration: none;
  -webkit-transition: color .2s ease-in;
  transition: color .2s ease-in; }

b {
  font-weight: 600; }

i {
  font-style: italic; }

small {
  font-size: 0.8125em; }

section {
  background: #000; }

blockquote::before, blockquote::after {
  content: '"'; }

.cntr-text {
  text-align: center; }

/*
  Filename: _index.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
/*
  Filename: _layout.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
/*.logged-in .secondary-nav {
  top: 32px;
}*/
.main-header {
  position: fixed;
  z-index: 100;
  height: 50px;
  width: 100%; }

.main-content {
  width: 100%; }

.hero {
  background-color: #434343;
  height: 50px;
  /*  background: url('../img/castle-hero-01-xs.jpg') fixed no-repeat $bg-hero;
  background-size: auto auto;
  background-position: center top;
  height: 80px;*/ }
  @media (min-width: 544px) {
    .hero {
      background-image: url("/r-img/castle-hero-01-lg.jpg");
      background-position: center top;
      background-size: auto 160px;
      height: 160px; } }
  @media (min-width: 720px) {
    .hero {
      background-size: auto 220px;
      height: 220px; } }
  @media (min-width: 940px) {
    .hero {
      background-image: url("/r-img/castle-hero-01-xl.jpg");
      background-size: auto 300px;
      height: 300px; } }
  @media (min-width: 1140px) {
    .hero {
      background-size: auto auto;
      background-position: center top;
      height: 360px; } }
  @media (min-width: 2200px) {
    .hero {
      background-size: 100% 360px; } }

.content {
  padding: 30px 0; }
  @media (min-width: 544px) {
    .content {
      padding-bottom: 50px;
      padding-top: 50px; } }
  @media (min-width: 720px) {
    .content {
      padding-bottom: 70px;
      padding-top: 70px; } }
  .content__heading {
    margin-bottom: 20px; }
    @media (min-width: 544px) {
      .content__heading {
        margin-bottom: 30px; } }
    @media (min-width: 720px) {
      .content__heading {
        margin-bottom: 40px; } }

.spacer {
  background: none;
  height: 100vh; }

.quote {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%; }

.main-footer {
  background: #434343;
  min-height: 50px;
  padding-top: 12px;
  text-align: center; }

/*
  Filename: _index.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
/*
  Filename: _main-header.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
.main-header {
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 544px) {
    .main-header {
      padding-left: 20px;
      padding-right: 20px; } }

.main-header__wrap {
  display: flex;
  padding: 5px 5px; }

.main-header__brand {
  flex: 1 1; }
  .main-header__brand a {
    display: inline-block;
    padding: 0 0.4375em;
    text-align: center;
    -webkit-transition: width .3s ease-in;
    transition: width .3s ease-in; }
    @media (min-width: 544px) {
      .main-header__brand a {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px; } }
    .main-header__brand a:link, .main-header__brand a:visited, .main-header__brand a:hover, .main-header__brand a:active {
      color: #ffc52a; }
  .main-header__brand span {
    display: inline-block;
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 85px;
    -webkit-transition: width .5s ease, opacity .3s ease, -webkit-transform .3s ease;
    transition: width .5s ease, opacity .3s ease, -webkit-transform .3s ease;
    transition: transform .3s ease, width .5s ease, opacity .3s ease;
    transition: transform .3s ease, width .5s ease, opacity .3s ease, -webkit-transform .3s ease;
    text-align: left; }

.main-header__btn {
  flex: 0 0 70px;
  display: inline-block;
  text-align: right; }
  .main-header__btn span {
    color: #ffc52a;
    display: inline-block;
    padding: 0;
    position: relative;
    text-align: center;
    -webkit-transition: color .3s ease-in;
    transition: color .3s ease-in;
    width: 45px; }
    @media (min-width: 544px) {
      .main-header__btn span {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px; } }
    .main-header__btn span:before, .main-header__btn span:after {
      width: 45px;
      position: absolute;
      left: 0;
      top: 0; }
    .main-header__btn span:before {
      content: "\2630";
      opacity: 1;
      -webkit-transition: opacity .5s ease-in;
      transition: opacity .5s ease-in; }
    .main-header__btn span:after {
      content: "\2716";
      opacity: 0;
      -webkit-transition: opacity .4s ease-in .2s;
      transition: opacity .4s ease-in .2s; }
    .main-header__btn span:hover {
      color: #fff;
      cursor: pointer; }
  .main-header__btn span.menu-btn-click:before {
    opacity: 0; }
  .main-header__btn span.menu-btn-click:after {
    opacity: 1; }

header p.min-brand span {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0; }

header p#brand.min-brand span {
  width: 0; }

/* 
  File     : _main-nav.scss
  Date: 10 Feb 17
  Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9); }

.js-menu-off {
  opacity: 0;
  z-index: -1; }

.js-menu-on {
  opacity: 1;
  z-index: 10; }

/*
  Filename: _main-menu.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/
/*.main-menu {
  
  //background:yellow;
  
  text-align: right;
  
}

.main-menu .menu-item {
  display: inline-block;
}*/
.main-menu {
  width: 240px;
  margin: 250px auto; }

.main-menu__item a:link, .main-menu__item a:visited,
.main-menu a:link, .main-menu a:visited {
  color: #ffc52a; }

.main-menu__item a:hover, .main-menu__item a:active,
.main-menu a:hover, .main-menu a:active {
  color: #000;
  background-color: rgba(255, 197, 42, 0.75); }

.main-menu__item--current a:link, .main-menu__item--current a:visited, 
.main-menu__item--current a:hover, .main-menu__item--current a:active,
.main-menu a.active:link, .main-menu a.active:visited, 
.main-menu a.active:hover, .main-menu a.active:active {
  background: #ffc52a;
  color: #000; }

#fullscreen-menu.js-menu-on li:nth-child(1) {
  -webkit-animation: fadeUp .4s ease-in forwards;
          animation: fadeUp .4s ease-in forwards; }

#fullscreen-menu.js-menu-on li:nth-child(2) {
  -webkit-animation: fadeUp .4s ease-in .1s forwards;
          animation: fadeUp .4s ease-in .1s forwards; }

#fullscreen-menu.js-menu-on li:nth-child(3) {
  -webkit-animation: fadeUp .4s ease-in .2s forwards;
          animation: fadeUp .4s ease-in .2s forwards; }

#fullscreen-menu.js-menu-on li:nth-child(4) {
  -webkit-animation: fadeUp .4s ease-in .3s forwards;
          animation: fadeUp .4s ease-in .3s forwards; }

#fullscreen-menu.js-menu-on li:nth-child(5) {
  -webkit-animation: fadeUp .4s ease-in .4s forwards;
          animation: fadeUp .4s ease-in .4s forwards; }

#fullscreen-menu.js-menu-on li:nth-child(6) {
  -webkit-animation: fadeUp .4s ease-in .4s forwards;
          animation: fadeUp .4s ease-in .4s forwards; }

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fadeUp {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

/* 
    File     : _hero.scss
    Date     : Feb 19, 2017
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.hero {
  position: relative;
  color: white; }

.hero__heading {
  position: absolute;
  left: 5px;
  bottom: 10px;
  margin-bottom: 0; }

/* 
    File     : _projects.scss
    Date     : 18 Feb 2017
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
@media (min-width: 544px) {
  .projects {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; } }

@media (min-width: 720px) {
  .projects {
    margin-bottom: 40px; } }

.projects__wrap {
  position: relative; }
  @media (min-width: 544px) {
    .projects__wrap {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between; } }

.projects__figure {
  margin-bottom: 25px;
  position: relative; }
  @media (min-width: 544px) {
    .projects__figure {
      flex: 0 1 46%;
      margin-bottom: 30px; } }
  @media (min-width: 720px) {
    .projects__figure {
      flex: 0 1 46%;
      margin-bottom: 35px; } }
  @media (min-width: 940px) {
    .projects__figure {
      flex: 0 1 45%; } }

@media (min-width: 544px) {
  .projects__figure:nth-child(2n) {
    top: 50px; } }

@media (min-width: 720px) {
  .projects__figure:nth-child(2n) {
    top: 80px; } }

.projects__image {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

@media (min-width: 940px) {
  .projects__caption {
    position: relative;
    left: -30px;
    top: -20px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 0% center;
            transform-origin: 0% center;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; } }

.projects__figure:hover .projects__image {
  -webkit-transform: scale(1.01);
          transform: scale(1.01); }

.projects__figure:hover .projects__caption {
  left: 0;
  top: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0); }

/* 
    File     : _contact-section.scss
    Date     : Mar 10, 2017
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.action {
  background: #ededed;
  color: #000; }
  .action__text {
    text-align: center; }
  .action__button {
    font-size: 1.25em;
    line-height: 1.15385em;
    font-weight: normal;
    background: none;
    border: 2px solid #434343;
    color: #000;
    display: block;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    width: 200px; }
    .action__button:hover {
      cursor: pointer;
      background: #434343;
      color: #ededed; }
    @media (min-width: 544px) {
      .action__button {
        font-size: 1.5em;
        line-height: 1.125em;
        padding: 12px;
        width: 225px; } }
    @media (min-width: 720px) {
      .action__button {
        font-size: 1.75em;
        width: 250px; } }

/* 
    File     : _contact-modal.scss
    Date     : Mar 10, 2017
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.contact-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px; }

.contact-text {
  text-align: center; }

.contact-form__label {
  display: none;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 600; }

.contact-form__text {
  border-width: 0 2px 2px 2px; }

.contact-form__textarea {
  border-width: 0 2px 2px 2px;
  height: 80px; }
  @media (min-width: 720px) {
    .contact-form__textarea {
      height: 120px; } }

.contact-form__submit {
  border-width: 2px;
  border-style: solid;
  padding: 4px 0;
  color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }
  .contact-form__submit:hover {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    color: #ffc52a;
    border-color: #ffc52a; }

.main-message {
  position: fixed;
  z-index: 100;
  top: -150px;
  right: 0;
  width: 100%;
  background: #000;
  min-height: 60px;
  -webkit-transition: top .2s ease-out;
  transition: top .2s ease-out;
  padding: 10px 20px 0 20px;
  text-align: center; }
  .main-message div {
    position: relative; }
  .main-message span {
    padding-top: 10px;
    cursor: pointer;
    font-size: 1.3em;
    position: absolute;
    right: 20px; }

.main-message div {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto; }

.show-message {
  top: 0px; }

.error-border {
  border-color: #ff3333; }

.error-text {
  color: #ff3333; }

.success-text {
  color: #ffc52a; }

/* 
    File     : _last.scss
    Date     : Mar 10, 2017
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic; }

.quote__text, .quote__author {
  font-size: 1.375rem;
  line-height: 1.15385em;
  font-weight: normal; }
  @media (min-width: 544px) {
    .quote__text, .quote__author {
      font-size: 1.5rem;
      line-height: 1.125em; } }
  @media (min-width: 720px) {
    .quote__text, .quote__author {
      font-size: 1.75rem; } }

.quote__text {
  margin-bottom: 20px; }

.quote__author {
  margin-bottom: 0; }

/* 
    File     : _forms.scss
    Date: 10 Feb 17
    Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
/* 
  File     : _blog-article.scss
  Date     : Date: 18 Feb 17
  Author   : Jaymes Young <jaymes@phoenixjaymes.com>
*/
.entry {
  margin-bottom: 30px;
  padding: 10px; }

.entry-header {
  margin-bottom: 20px; }

.entry-footer {
  padding: 20px; }
  .entry-footer__item {
    display: inline; }

/*
  Filename: _main-footer.scss
  Date: 10 Feb 17
  Programmer: Jaymes Young
*/

/*# sourceMappingURL=style.css.map */

